<template>
  <div class="flex mt-5">
    <p class="font-bold">Registro de apartados</p>
  </div>
  <div class="flex justify-between p-4 bg-white ">
    <div class="flex flex-col">
      <span class="text-xs font-bold">N° Envio</span>
      <span class="text-xs">{{ header.EnvioId }}</span>
    </div>
    <div class="flex flex-col">
      <span class="text-xs font-bold">Guia</span>
      <span class="text-xs">{{ header.Guia }}</span>
    </div>
    <div class="flex flex-col">
      <span class="text-xs font-bold">Fecha</span>
      <span class="text-xs">{{ header.DocDate }}</span>
    </div>
    <div class="flex flex-col">
      <span class="text-xs font-bold">Bodega Destino</span>
      <span class="text-xs">{{ header.WhsName }}</span>
    </div>
  </div>
  <div>
    <Table :header="header"/>
  </div>
</template>
<script setup>
import { computed, defineAsyncComponent } from 'vue'
const Table = defineAsyncComponent(() => import('../../components/apartados/table.vue'))
const header = computed(() => {
  const listMds = JSON.parse(localStorage.getItem('confirmarRecepcion')).filter(x => x.Status === true)
  const temp = listMds[0]
  return {
    EnvioId: temp ? temp.EnvioId : '',
    Guia: temp ? temp.Guia : '',
    DocDate: temp ? temp.Date : '',
    WhsName: temp ? temp.WhsName : '',
    WhsCode: temp ? temp.WhsCode : ''
  }
})
</script>
